import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import scrollTo from 'gatsby-plugin-smoothscroll';

const AContainer = styled.div`
  background-color: rgba(120,120,120,0.5);
  padding: 5rem 0 5rem 0;  
  margin: 5rem 0 5rem 0;  
  color: whitesmoke;
  clip-path: polygon(0% 15%, 100% 0%, 100% 85%, 0% 100%);

`;

const AimingHigh = (props) => {
  const {titleText} = props
  return (        
          <AContainer>
                <h4>{titleText}</h4>
                    <Button              
                    onClick={() => scrollTo("#contact-us")}
                    variant="primary">
                        Contact us
                    </Button>
          </AContainer>
  );

}

export default AimingHigh;